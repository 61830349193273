import { RouteRecordRaw } from "vue-router"
import { PayCircleOutlined } from "@ant-design/icons-vue"
import { FunctionalComponent } from "vue"
import { AntdIconProps } from "@ant-design/icons-vue/lib/components/AntdIcon"
import RouteParent from "@/components/RouteParent.vue"

const pages: Array<RouteRecordRaw & { meta: PageMeta }> = [
  {
    path: '/',
    name: 'SalesWorkOrder',
    redirect: '/list',
    component: RouteParent,
    meta: {
      title: '销售工单',
      icon: PayCircleOutlined,
      themeColor: '#1890ff'
    },
    children: [
      {
        path: '/list',
        name: 'SalesWorkOrderList',
        component: () => import(/* webpackChunkName: "SalesWorkOrderList" */ '@/views/SalesWorkOrder/List.vue')
      },
      {
        path: '/form',
        name: 'SalesWorkOrderForm',
        component: () => import(/* webpackChunkName: "SalesWorkOrderForm" */ '@/views/SalesWorkOrder/Form.vue'),
        props: route => {
          if (route.params.defaultSource) {
            return { defaultSource: JSON.parse(route.params.defaultSource as string) }
          }
        }
      }
    ]
  }
]

export interface PageMeta {
  title: string;
  icon: FunctionalComponent<AntdIconProps>;
  themeColor: string;
}

export default pages