
import GlobalRef from "@/store/GlobalRef";
// 覆盖 antd 的本地化配置
import {ConfigProvider} from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import {defineComponent} from "vue";
import DingAuth from "@/api/inc/DingAuth";
import UserStorage from "@/store/UserStorage";

export default defineComponent({
  components: {
    ConfigProvider
  },
  setup() {
    return {
      zhCN,
      isLoading: GlobalRef.globalLoading,
      token: UserStorage.useToken()
    };
  },
  watch: {
    token: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.login()
        }
      }
    }
  },
  methods: {
    login() {
      DingAuth.login();
    }
  }
})
