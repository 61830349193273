import axios from 'axios';
import UserStorage from "@/store/UserStorage";
import {message} from "ant-design-vue";

const Request = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

Request.interceptors.request.use(config => {
  const token = UserStorage.useToken()
  if (token.value) {
    config.headers!.apiauth = token.value
  }
  return config;
}, error => {
  return Promise.reject(error);
})

Request.interceptors.response.use(({data}) => {
  if (data.code < 0) {
    if (data.code === -14) {
      UserStorage.set(null)
    } else {
      message.error(data.msg)
    }
    throw new Error(data.msg)
  } else {
    return data.data
  }
}, error => {
  return Promise.reject(error);
})

export default Request
