import {ref} from "vue";

/**
 * 目前本项目的设计规模并不需要特意引入 Vuex，请使用组合式 Api 直接读取操作相关引用。
 * 如果需要自定义 getter 和 setter，请使用 {@linkplain https://v3.cn.vuejs.org/api/refs-api.html#customref customRef}
 * 或 {@linkplain https://v3.cn.vuejs.org/api/computed-watch-api.html#computed computed}。
 */
export default class GlobalRef {
  static readonly globalLoading = ref(false)
}
