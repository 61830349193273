import { Button, Card, Col, Form, Input, List, Result, Row, Spin, Tag } from "ant-design-vue"

export default {
  install(app) {
    // use components
    app.use(Row)
    app.use(Col)
    app.use(Button)
    app.use(List)
    app.use(Card)
    app.use(Spin)
    app.use(Result)
    app.use(Form)
    app.use(Input)
    app.use(Tag)

    if (process.env.NODE_ENV === 'development') {
      console.log(
        '%c LazyLoad %c Antd was loaded partially %c',
        'background:#35495e;padding:1px;border-radius:3px 0 0 3px;color:#fff',
        'background:#1890ff;padding:1px;border-radius:0 3px 3px 0;color:#fff',
        'background:transparent'
      )
    }
  }
} as import('@vue/runtime-core/dist/runtime-core').Plugin
