import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import antd from "@/config/antd";
import './main.less'

const app = createApp(App)

app.use(router)
app.use(antd)

app.mount('#app')
