import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import pages from "@/router/pages";
import GlobalRef from "@/store/GlobalRef";

const routes: Array<RouteRecordRaw> = [
  ...pages
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(() => {
  GlobalRef.globalLoading.value = true
})

router.afterEach((to, from, failure) => {
  if (!failure && to.meta?.title) {
    document.title = to.meta.title as string
  }
  GlobalRef.globalLoading.value = false
})

export default router
