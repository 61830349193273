import dd from 'dingtalk-jsapi'
import Request from "@/api/inc/Request"
import UserStorage from "@/store/UserStorage"
import { message } from "ant-design-vue"

export default class DingAuth {
  static async getAuthCode(): Promise<string> {
    return (await dd.runtime.permission.requestAuthCode({
      corpId: process.env.VUE_APP_DING_CROP_ID,
    })).code
  }

  static async getToken(): Promise<TokenBody> {
    return Request.get("/ding/login/code", {
      params: {
        code: await DingAuth.getAuthCode()
      }
    })
  }

  static async login(): Promise<void> {
    try {
      await dd.device.notification.showPreloader({
        text: "登录中...",
      })
      const res = await DingAuth.getToken()
      UserStorage.set(res)
    } catch (e) {
      console.error(e)
      message.error("登录失败")
    } finally {
      await dd.device.notification.hidePreloader({})
    }
  }
}

export interface TokenBody {
  apiAuth: string
}