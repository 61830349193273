import {TokenBody} from "@/api/inc/DingAuth";
import { ref } from "vue";

export default class UserStorage {
  private static KEY = 'USER_INFO'

  static get(): TokenBody | null {
    const storage = localStorage.getItem(UserStorage.KEY)
    return storage ? JSON.parse(storage) : null
  }

  static set(token: TokenBody | null): void {
    if (token) {
      localStorage.setItem(UserStorage.KEY, JSON.stringify(token))
    } else {
      localStorage.removeItem(UserStorage.KEY)
    }
    UserStorage.token.value = token?.apiAuth ?? ''
  }


  private static readonly token = ref('')

  static useToken () {
    if (!UserStorage.token.value) {
      const token = UserStorage.get()
      if (token) {
        UserStorage.token.value = token.apiAuth
      }
    }
    return UserStorage.token
  }
}
